<template>
  <div class="yogi-ptsal">

    <b-card class="">
      <!-- media -->
      <div class="custom-search d-flex justify-content-between">
        <b-media no-body vertical-align="center">
          <b-media-aside class="mr-75">
            <b-avatar size="38" />
            <!-- :src="blogEdit.avatar" -->
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-25">{{ data?.user?.perusahaan }}</h6>
            <small
              ><i>{{ data?.user?.full_name }}</i></small
            >
            <b-card-text>
              <strong>Diajukan</strong>
              {{ dateHumanReadAble(data?.created_at) }} dengan <strong>Rencana Uraian Kegiatan</strong> : <strong>{{ data?.rencana_uraian_keg }}</strong></b-card-text
            >
          </b-media-body>
        </b-media>
        <span>
          <h1>#Form Operasional</h1>
        </span>
      </div>

      <b-row class="mt-2">
        <!-- <b-col md="6" cols="6">
          <validation-provider
            #default="{ errors }"
            name="Pengguna Jasa"
            rules="required"
          >
            <b-form-group
              label="Pengguna Jasa"
              label-for="lbl-pengguna-jasa"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="lbl-pengguna-jasa"
                v-model="form.jops_pengajuan_dts.pengguna_jasa"
                :state="errors.length > 0 ? false : null"
                placeholder="Pengguna Jasa"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col> -->
        <b-col md="8" cols="6"> </b-col>
        <b-col md="4" cols="12">
          <b-form-group label="Tanggal" label-for="lbl-tanggal">
            <template #label>
              <div class="d-flex align-items-center">
                <span>Tanggal</span>
                <a class="ml-1 text-primary">
                  ( <feather-icon icon="CalendarIcon" /> Jika kosong , otomatis
                  set as Today )
                </a>
              </div>
            </template>
            <validation-provider #default="{ errors }" name="Tanggal">
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="CalendarIcon" />
                </b-input-group-prepend>
                <flat-pickr
                  v-if="isNotJasaOPSViewer()"
                  id="lbl-tanggal"
                  v-model="form.jops_pengajuan_dts.tanggal"
                  class="form-control"
                  static="true"
                  :state="errors.length > 0 ? false : null"
                  :config="dpconfig"
                  @on-change="onStartChange"
                />
                <b-form-input
                  v-else
                  id="lbl-tanggal"
                  :value="dateHumanReadAble(form.jops_pengajuan_dts.tanggal)"
                  :readonly="true"
                  :state="errors.length > 0 ? false : null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="divider">
        <div class="divider-text">
          <b-badge pill class="d-block" :variant="'light-primary'">
            Peralatan
          </b-badge>
        </div>
      </div>
      <b-row>
        <b-col cols="4">
          <b-form-group label="Crane" label-for="lbl-plt-crane">
            <validation-provider #default="{ errors }" name="Crane">
              <b-input-group>
                <!-- <b-input-group-prepend is-text>
                  <feather-icon icon="ToolIcon" />
                </b-input-group-prepend> -->
                <v-select
                  v-if="isNotJasaOPSViewer()"
                  class="flex-grow-1"
                  id="lbl-plt-crane"
                  multiple
                  v-model="form.jops_pengajuan_dts.plt_crane"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="options.plt_crane"
                  :reduce="(option) => option.value"
                  :selectable="(option) => !option.value.includes('null')"
                  label="text"
                  item-text="text"
                  item-value="value"
                />
                <span v-else>
                  <span
                    v-for="(item, index) in form.jops_pengajuan_dts.plt_crane"
                    :id="index"
                    :key="index"
                  >
                    {{ item }} -
                  </span>
                </span>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Forklift" label-for="lbl-plt-forklift">
            <validation-provider #default="{ errors }" name="Forklift">
              <b-input-group>
                <!-- <b-input-group-prepend is-text>
                  <feather-icon icon="ToolIcon" />
                </b-input-group-prepend> -->
                <v-select
                  v-if="isNotJasaOPSViewer()"
                  class="flex-grow-1"
                  id="lbl-plt-forklift"
                  multiple
                  v-model="form.jops_pengajuan_dts.plt_forklift"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="options.plt_forklift"
                  :reduce="(option) => option.value"
                  :selectable="(option) => !option.value.includes('null')"
                  label="text"
                  item-text="text"
                  item-value="value"
                />
                <span v-else>
                  <span
                    v-for="(item, index) in form.jops_pengajuan_dts.plt_forklift"
                    :id="index"
                    :key="index"
                  >
                    {{ item }} -
                  </span>
                </span>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group label="Tronton" label-for="lbl-plt-tronton">
            <validation-provider #default="{ errors }" name="Tronton"
              ><b-input-group>
                <!-- <b-input-group-prepend is-text>
                  <feather-icon icon="ToolIcon" />
                </b-input-group-prepend> -->
                <v-select
                  v-if="isNotJasaOPSViewer()"
                  class="flex-grow-1"
                  id="lbl-plt-tronton"
                  multiple
                  v-model="form.jops_pengajuan_dts.plt_tronton"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="options.plt_tronton"
                  :reduce="(option) => option.value"
                  :selectable="(option) => !option.value.includes('null')"
                  label="text"
                  item-text="text"
                  item-value="value"
                />
                <span v-else>
                  <span
                    v-for="(item, index) in form.jops_pengajuan_dts.plt_tronton"
                    :id="index"
                    :key="index"
                  >
                    {{ item }} -
                  </span>
                </span>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="divider">
        <div class="divider-text">
          <b-badge pill class="d-block" :variant="'light-primary'">
            Tenaga Kerja
          </b-badge>
        </div>
      </div>
      <b-row>
        <b-col md="4" cols="6">
          <b-form-group label="Rigger" label-for="lbl-Rigger">
            <validation-provider
              #default="{ errors }"
              name="Rigger"
              vid="Rigger"
              :rules="{
                required: false
              }"
            >
              <b-input-group>
                <b-form-input
                  ref="riggerInput"
                  id="lbl-Rigger"
                  v-model="form.jops_pengajuan_dts.tk_rigger"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  :placeholder="'Rigger'"
                  :disabled="!isNotJasaOPSViewer()"
                />
                <b-input-group-prepend
                  is-text
                  @click="$refs.riggerInput.focus()"
                >
                  PRS
                </b-input-group-prepend>
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4" cols="6">
          <b-form-group label="Tallyman" label-for="lbl-Tallyman">
            <validation-provider
              #default="{ errors }"
              name="Tallyman"
              vid="Tallyman"
              :rules="{
                required: false
              }"
            >
              <b-input-group>
                <b-form-input
                  id="lbl-Tallyman"
                  v-model="form.jops_pengajuan_dts.tk_tallyman"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  :placeholder="'Tallyman'"
                  :disabled="!isNotJasaOPSViewer()"
                />
                <b-input-group-append is-text> PRS </b-input-group-append>
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4" cols="6">
          <b-form-group label="HSE" label-for="lbl-HSE">
            <validation-provider
              #default="{ errors }"
              name="HSE"
              vid="HSE"
              :rules="{
                required: false
              }"
            >
              <b-input-group>
                <b-form-input
                  id="lbl-HSE"
                  v-model="form.jops_pengajuan_dts.tk_hse"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  :placeholder="'HSE'"
                  :disabled="!isNotJasaOPSViewer()"
                />
                <b-input-group-append is-text> PRS </b-input-group-append>
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4" cols="6">
          <b-form-group label="Supervisor" label-for="lbl-Supervisor">
            <validation-provider
              #default="{ errors }"
              name="Supervisor"
              vid="Supervisor"
              :rules="{
                required: false
              }"
            >
              <b-input-group>
                <b-form-input
                  id="lbl-Supervisor"
                  v-model="form.jops_pengajuan_dts.tk_supervisor"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  :placeholder="'Supervisor'"
                  :disabled="!isNotJasaOPSViewer()"
                />
                <b-input-group-append is-text> PRS </b-input-group-append>
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4" cols="6">
          <b-form-group label="Jettyman" label-for="lbl-Jettyman">
            <validation-provider
              #default="{ errors }"
              name="Jettyman"
              vid="Jettyman"
              :rules="{
                required: false
              }"
            >
              <b-input-group>
                <b-form-input
                  id="lbl-Jettyman"
                  v-model="form.jops_pengajuan_dts.tk_jettyman"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  :placeholder="'Jettyman'"
                  :disabled="!isNotJasaOPSViewer()"
                />
                <b-input-group-append is-text> PRS </b-input-group-append>
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4" cols="6">
          <b-form-group label="Security" label-for="lbl-Security">
            <validation-provider
              #default="{ errors }"
              name="Security"
              vid="Security"
              :rules="{
                required: false
              }"
            >
              <b-input-group>
                <b-form-input
                  id="lbl-Security"
                  v-model="form.jops_pengajuan_dts.tk_security"
                  :state="errors.length > 0 ? false : null"
                  type="number"
                  :placeholder="'Security'"
                  :disabled="!isNotJasaOPSViewer()"
                />
                <b-input-group-append is-text> PRS </b-input-group-append>
              </b-input-group>

              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="divider">
        <div class="divider-text">
          <b-badge pill class="d-block" :variant="'light-primary'">
            Others
          </b-badge>
        </div>
      </div>
      <b-row>
        <b-col md="8" cols="12">
          <b-form-group
            label="Work Description"
            label-for="lbl-Work Description"
          >
            <validation-provider
              #default="{ errors }"
              name="Work Description"
              vid="Work Description"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="BriefcaseIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="lbl-Work Description"
                  v-model="form.jops_pengajuan_jrs.work_desc"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="'Muat IBC'"
                  :disabled="!isNotJasaOPSViewer()"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4" cols="12">
          <b-form-group label="Order No" label-for="lbl-Order No">
            <validation-provider
              #default="{ errors }"
              name="Order No"
              vid="Order No"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="HashIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="lbl-Order No"
                  v-model="form.jops_pengajuan_jrs.order_no"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="''"
                  :disabled="!isNotJasaOPSViewer()"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6" cols="12">
          <b-form-group label="Segment" label-for="lbl-Segment">
            <template #label>
              <div class="d-flex align-items-center">
                <span>Segment</span>
                <a
                  class="ml-1 text-primary"
                  @click.prevent="navigateToRegisterSegment"
                >
                  ( <feather-icon icon="LinkIcon" /> Register New)
                </a>
              </div>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Segment"
              vid="Segment"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="LinkIcon" />
                </b-input-group-prepend>
                <v-select
                  :disabled="!isNotJasaOPSViewer()"
                  class="flex-grow-1"
                  id="lbl-Segment"
                  v-model="form.jops_pengajuan_jrs.segment"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="options.segments"
                  :reduce="(option) => option.value"
                  :selectable="(option) => !option.value.includes('null')"
                  label="text"
                  item-text="text"
                  item-value="value"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6" cols="12">
          <b-form-group label="Vessel Identity" label-for="lbl-Vessel Identity">
            <validation-provider
              #default="{ errors }"
              name="Vessel Identity"
              vid="Vessel Identity"
            >
              <b-input-group>
                <b-input-group-prepend is-text>
                  <feather-icon icon="AnchorIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="lbl-Vessel Identity"
                  v-model="form.jops_pengajuan_jrs.vessel_identity"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="''"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12" v-if="data.jops_pengajuan_dts[0]?.user?.full_name">
          <div class="divider">
            <div class="divider-text">
              Updated Form By :
              <b-badge pill class="d-block" :variant="'light-primary'">
                {{ data.jops_pengajuan_dts[0]?.user?.full_name }}
              </b-badge>
              Last Updated At :
              {{ dateHumanReadAble(data.jops_pengajuan_dts[0]?.created_at) }}
            </div>
          </div>
        </b-col>
        <b-col md="12" cols="12">
          <b-button
            v-if="isNotJasaOPSViewer()"
            class="mt-1"
            block
            variant="gradient-primary"
            :disabled="fetchLoading"
            @click="handleSimpanFormOperasional()"
          >
            <feather-icon
              v-if="!fetchLoading"
              icon="DatabaseIcon"
              :style="{ cursor: 'pointer' }"
              class="mr-50"
            />
            <b-spinner v-if="fetchLoading" small></b-spinner>
            <span class="align-middle" v-text="'Simpan Form Operasional'" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <div class="custom-search d-flex justify-content-between">
        <b-media no-body vertical-align="center">
          <b-media-aside class="mr-75">
            <b-avatar size="38" />
            <!-- :src="blogEdit.avatar" -->
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-25">{{ data?.user?.perusahaan }}</h6>
            <small
              ><i>{{ data?.user?.full_name }}</i></small
            >
            <b-card-text>
              <strong>Diajukan</strong>
              {{ dateHumanReadAble(data?.created_at) }} dengan <strong>Rencana Uraian Kegiatan</strong> : <strong>{{ data?.rencana_uraian_keg }}</strong></b-card-text
            >
          </b-media-body>
        </b-media>
        <span>
          <h1>#Daftar Kegiatan Operasional</h1>
        </span>
      </div>
      <div class="d-flex justify-content-end align-items-end">
        <b-button
          v-if="isNotJasaOPSViewer()"
          class="mt-1"
          variant="gradient-success"
          :disabled="fetchLoading"
          :to="{
            name: 'pelayanan-jasa-operasional-pengajuan-wizard-kegiatan',
            params: {
              jops_pengajuan_dt_id: data.id,
              jops_pengajuan_dt: data.jops_pengajuan_dts[0],
              api: 'jops_pengajuan',
              previous_link: {
                id: data.id,
                name: 'pelayanan-jasa-operasional-pengajuan-wizard',
                api: 'jops_pengajuan'
              }
            }
          }"
        >
          <feather-icon
            v-if="!fetchLoading"
            icon="BookIcon"
            :style="{ cursor: 'pointer' }"
            class="mr-50"
          />
          <b-spinner v-if="fetchLoading" small></b-spinner>
          <span
            class="align-middle"
            v-text="'Buat Daftar Kegiatan Operasional'"
          />
        </b-button>
      </div>
      <b-card>
        <b-list-group
          v-if="data.jops_pengajuan_dts[0].jops_pengajuan_dts_kegs.length !== 0"
        >
          <b-list-group-item
            v-for="(item, id) in data.jops_pengajuan_dts[0]
              .jops_pengajuan_dts_kegs"
            :key="id"
            class="p-1"
          >
            <div
              class="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between"
            >
              <span class="flex-grow-1 w-100">
                <b-row>
                  <b-col md="3" cols="12">
                    <ul class="list-unstyled">
                      <li>
                        <strong>{{ item.uraian_kegiatan }}</strong>
                      </li>
                      <li>
                        <b-badge variant="info" pill class="badge-round">
                          <feather-icon icon="LayersIcon" class="mr-25" />
                          <strong
                            >({{ item.jumlah1 }}
                            {{ item.jumlah_s1 || 'TON' }} )</strong
                          >
                        </b-badge>
                        /
                        <b-badge variant="info" pill class="badge-round">
                          <strong
                            >({{ item.jumlah2 }}
                            {{ item.jumlah_s2 || 'M3' }} )</strong
                          >
                        </b-badge>
                      </li>
                      <!-- <li>
                        Created AT {{ dateHumanReadAble(item.created_at) }}
                      </li> -->
                    </ul>
                  </b-col>
                  <b-col md="3" sm="12" cols="12">
                    <ul class="list-unstyled">
                      <li class="mb-1">
                        <b-badge
                          variant="light-primary"
                          pill
                          class="badge-round w-100"
                        >
                          <feather-icon icon="ClockIcon" class="mr-25" />
                          <i>Jam</i>&nbsp;<strong
                            >{{ item.time }} / {{ item.total_jam }}</strong
                          >
                        </b-badge>
                      </li>

                      <li>
                        <b-badge
                          variant="light-primary"
                          pill
                          class="badge-round w-100"
                        >
                          <feather-icon icon="ClockIcon" class="mr-25" />
                          <strong>{{
                            dateHumanReadAble(item.mulai, 'D MMMM YYYY')
                          }}</strong>
                          <i>&nbsp;Sampai&nbsp;</i>
                          <strong>{{
                            dateHumanReadAble(item.selesai, 'D MMMM YYYY')
                          }}</strong>
                        </b-badge>
                      </li>
                    </ul>
                  </b-col>
                  <b-col md="6" cols="12">
                    <ul class="list-unstyled">
                      <li class="mb-1">
                        <b-badge
                          variant="light-secondary"
                          pill
                          class="badge-round w-100"
                        >
                          <feather-icon icon="FeatherIcon" class="mr-25" />
                          Notes : {{ item?.note ?? '-' }}
                        </b-badge>
                      </li>
                      <li>
                        <b-badge
                          v-if="item.jasa_dermaga"
                          variant="primary"
                          pill
                          class="badge-round"
                        >
                          <feather-icon icon="AnchorIcon" class="" />
                          <small>&nbsp;Jasa Dermaga</small>
                        </b-badge>
                        &nbsp;
                        <b-badge
                          v-if="item.jasa_stevodoring"
                          variant="primary"
                          pill
                          class="badge-round"
                        >
                          <feather-icon icon="TruckIcon" class="" />
                          <small>&nbsp;Jasa Stevodoring</small>
                        </b-badge>
                        &nbsp;
                        <b-badge
                          v-if="item.jasa_cargodoring"
                          variant="primary"
                          pill
                          class="badge-round"
                        >
                          <feather-icon icon="BoxIcon" class="" />
                          <small>&nbsp;Jasa Cargodoring</small>
                        </b-badge>
                        &nbsp;
                        <b-badge
                          v-if="item.lift_on_off"
                          variant="primary"
                          pill
                          class="badge-round"
                        >
                          <feather-icon icon="Maximize2Icon" class="" />
                          <small>&nbsp;Lift ON / OFF</small>
                        </b-badge>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
              </span>

              <!-- Icons (Edit & Delete) -->
              <div
                v-if="isNotJasaOPSViewer()"
                class="d-flex flex-column flex-md-row align-items-center mt-2 mt-md-0"
              >
                <!-- Edit Icon -->
                <b-button
                  variant="outline-primary"
                  class="rounded-circle p-1 d-flex align-items-center justify-content-center"
                  :to="{
                    name: 'pelayanan-jasa-operasional-pengajuan-wizard-kegiatan',
                    params: {
                      id: item.id,
                      jops_pengajuan_dt_id: data.id,
                      jops_pengajuan_dt: data.jops_pengajuan_dts[0],
                      api: 'jops_pengajuan_dts_keg',
                      previous_link: {
                        id: data.id,
                        name: 'pelayanan-jasa-operasional-pengajuan-wizard',
                        api: 'jops_pengajuan'
                      }
                    }
                  }"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <!-- Delete Icon -->
                <span
                  class="rounded-circle border border-danger d-flex align-items-center justify-content-center m-1"
                  style="width: 32px; height: 32px; cursor: pointer"
                  @click.prevent="handleDeleteKegiatanOperasional(item.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger"
                    size="16"
                  />
                </span>
              </div>
            </div>
            <hr />

            <small
              ><feather-icon icon="ClockIcon" class="mr-25" /> Created at
              {{ dateHumanReadAble(item?.created_at) }} and
              <feather-icon icon="ClockIcon" class="mr-25" /> Last Updated at
              {{ dateHumanReadAble(item?.updated_at) }}
              <feather-icon icon="UserIcon" class="mr-25" /> By
              {{ item?.user?.full_name }}</small
            >
          </b-list-group-item>
        </b-list-group>
        <span v-else> -</span>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import moment from 'moment'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import flatPickr from 'vue-flatpickr-component'
import router from '@/router'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { heightTransition } from '@core/mixins/ui/transition'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BCardText,
  BAvatar,
  BBadge,
  BInputGroup,
  BInputGroupAppend
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BBadge,
    Indonesian,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BCardActions,
    BCardText,
    Cleave
  },
  mixins: [heightTransition],
  data() {
    return {
      fetchLoading: false,
      fetchLoadingValue: 25,
      yoTimer: null /* fetching data progress bar #interval */,
      dataID: this.$router.currentRoute.params.id,
      data: {},
      form: {
        jops_pengajuan_dts: {
          tanggal: '',
          plt_crane: '',
          plt_forklift: '',
          plt_tronton: '',
          tk_rigger: '',
          tk_tallyman: '',
          tk_hse: '',
          tk_supervisor: '',
          tk_jettyman: '',
          tk_security: ''
        },
        jops_pengajuan_jrs: {
          work_desc: '',
          segment: '',
          vessel_identity: '',
          order_no: ''
        }
      },
      dpconfig: {
        // default
        wrap: true,
        enableTime: true,
        time_24hr: true,
        altInput: true,
        altFormat: 'j F Y - H:i',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      options: {
        plt_crane: [
          { text: 'Crane	CCS 03 (50 Ton)', value: 'Crane	CCS 03 (50 Ton)' },
          { text: 'Crane	CCS 02 (50 Ton)', value: 'Crane	CCS 02 (50 Ton)' }
        ],
        plt_forklift: [
          { text: 'Forklift	FK 02 (3 Ton)', value: 'Forklift	FK 02 (3 Ton)' },
          { text: 'Forklift	FK 03 (5 Ton)', value: 'Forklift	FK 03 (5 Ton)' },
          { text: 'Forklift	FK 05 (5 Ton)', value: 'Forklift	FK 05 (5 Ton)' },
          { text: 'Forklift	FK 06 (5 Ton)', value: 'Forklift	FK 06 (5 Ton)' },
          { text: 'Forklift	FK 07 (3 Ton)', value: 'Forklift	FK 07 (3 Ton)' },
          { text: 'Forklift	FK 08 (5 Ton)', value: 'Forklift	FK 08 (5 Ton)' },
          { text: 'Forklift	FK 09 (7 Ton)', value: 'Forklift	FK 09 (7 Ton)' },
          { text: 'Forklift	FK 10 (10 Ton)', value: 'Forklift	FK 10 (10 Ton)' },
          { text: 'Forklift	FK 01 (2,5 Ton)', value: 'Forklift	FK 01 (2,5 Ton)' }
        ],
        plt_tronton: [
          { text: 'Tronton	CD 520 VN', value: 'Tronton	CD 520 VN' },
          { text: 'Tronton	FS 510 VZ', value: 'Tronton	FS 510 VZ' },
          // { text: 'Tronton	FS 510 VZ', value: 'Tronton	FS 510 VZ' },
          { text: 'Tronton	FV 413 J', value: 'Tronton	FV 413 J' }
        ],
        segments: []
      }
    }
  },
  mounted() {
    document.body.style.zoom = '100%'
  },
  destroyed() {
    document.body.style.zoom = '100%'
  },
  computed: {
    selectedPltCrane: {
      get() {},
      set(value) {}
    }
  },
  async created() {
    await this.fetchData()
  },
  methods: {
    isJasaOPSPengguna() {
      if(this.$can('manage', 'all')){
        return true
      }
      if(this.$can('Jasa OPS Pengguna', '')){
        /* return as enable */
        return true
      } else {
        /* return as disable */
        return false
      }
    },
    isNotJasaOPSViewer() {
      if(this.$can('manage', 'all')){
        return true
      }
      if (
        this.$store.state.app.roleName === 'viewer' ||
        this.$store.state.app.roleName === 'jetty man' ||
        this.$store.state.app.roleName === 'security' ||
        this.isJasaOPSPengguna()
      ) {
        /* return as disable */
        return false
      } else {
        /* return as enable */
        return true
      }
    },
    isTimerDone() {
      clearInterval(this.yoTimer)
      this.yoTimer = null
      this.fetchLoading = false
    },
    isTimerStart() {
      this.fetchLoading = true
      this.yoTimer = setInterval(() => {
        if (this.fetchLoadingValue <= 75 && this.fetchLoading >= 0) {
          this.fetchLoadingValue += 25
        } else {
          this.fetchLoadingValue = 0
        }
      }, 1000)
    },
    getJasa(item) {
      try {
        const jasaMap = {
          'Jasa Dermaga': item.jasa_dermaga,
          'Jasa Stevedoring': item.jasa_stevodoring,
          'Jasa Cargodoring': item.jasa_cargodoring,
          'Lift On/Off': item.lift_on_off
        }

        // Find the first key where value is true
        const foundJasa = Object.entries(jasaMap).find(
          ([_, value]) => value === true
        )

        return foundJasa ? foundJasa[0] : '-'
      } catch (error) {
        return '-'
      }
    },

    async handleDeleteKegiatanOperasional(itemID) {
      this.$swal({
        title: 'Are you sure?',
        text: `apakah yakin ingin menghapus data`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: `Yes, delete it!`,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          useJwt.http
            .delete(`jops_pengajuan_dts_keg/${itemID}/0`)
            .then(async () => {
              this.$swal({
                title: 'Berhasil',
                text: 'Berhasil menghapus Kegiatan Operasional',
                icon: 'success',
                showCancelButton: false
              })
              await this.fetchData()
            })
            .catch((error) => {
              this.$swal({
                title: 'OOPS',
                text: 'Tidak Berhasil menghapus Kegiatan Operasional',
                icon: 'danger',
                showCancelButton: false
              })
            })
        } else {
        }
      })
    },
    async handleSimpanFormOperasional() {
      const dataID = this.dataID
      this.isTimerStart()
      await useJwt.http
        .put(`jops_pengajuan/${dataID}/admin_ops`, this.form)
        .then(async () => {
          this.$swal({
            title: 'Berhasil',
            text: 'Berhasil menyimpan Form Operasional',
            icon: 'success',
            // showConfirmButton: false,
            showCancelButton: false
            // showCloseButton: false,
          })
          await this.fetchData()
        })
        .catch((error) => {
          this.$swal({
            title: 'An Error',
            text: 'Something wrong please try again laters.',
            icon: 'danger',
            // showConfirmButton: false,
            showCancelButton: false
            // showCloseButton: false,
          })
          this.isTimerDone()
        })
    },
    navigateToRegisterSegment() {
      if(this.isNotJasaOPSViewer()){
        this.$router.push({ name: 'pelayanan-jasa-operasional-segment' })
      } else {
        this.$swal({
          position: 'top-end',
          icon: 'warning',
          title: 'You dont have permission',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      }
    },
    dateHumanReadAble(value, _format) {
      const format = moment(value).format(_format ?? 'D MMMM YYYY - HH:mm')
      if (format === 'Invalid date') {
        return '-'
      }
      return format
    },
    async fetchData() {
      const dataID = this.dataID

      if (dataID !== undefined) {
        this.isTimerStart()
        await useJwt.http.get(`jops_segment`).then((response) => {
          this.isTimerDone()
          this.options.segments = response.data[`jops_segments`].map((item) => {
            return {
              text: `${item.name}`,
              value: `${item.name}`
            }
          })
        })

        this.isTimerStart()
        await useJwt.http.get(`jops_pengajuan/${dataID}`).then((response) => {
          this.isTimerDone()
          this.data = response.data[`jops_pengajuans`]
          try {
            const {
              status,
              user,
              id,
              jops_pengajuan_dts_kegs,
              created_at,
              updated_at,
              pengguna_jasa,
              sign_diperiksa_oleh,
              sign_menyetujui,
              sign_pengguna_jasa,
              ...responseUsageDTS
            } = this.data.jops_pengajuan_dts[0]
            this.form.jops_pengajuan_dts = responseUsageDTS
            if (responseUsageDTS.tanggal == null) {
              this.form.jops_pengajuan_dts.tanggal =
                moment().format('YYYY-M-DD HH:mm')
            }
          } catch (error) {}
          try {
            const {
              client,
              status,
              user,
              id,
              date,
              created_at,
              updated_at,
              sign_administration,
              sign_company_representative,
              sign_operation_manager,
              ...responseUsageJR
            } = this.data.jops_pengajuan_jrs[0]
            this.form.jops_pengajuan_jrs = responseUsageJR
          } catch (error) {
            this.isTimerDone()
          }
        })
      } else {
        /*  */
        this.$swal({
          title: 'Page Secured',
          text: 'This page is secured and cannot be accessed directly via the URL. Please navigate from the list table or the index of Pengajuan Jasa.',
          icon: 'info',
          // showConfirmButton: false,
          showCancelButton: false
          // showCloseButton: false,
        }).then(() => this.$router.go(-1))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.yogi-ptsal {
  .yo-card {
    &:hover {
      transition: all 0.1s;
      scale: 1.1;
      box-shadow: 0 4px 8px rgb(0, 119, 255);
      opacity: 0.9;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
