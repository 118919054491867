var render = function () {
  var _vm$data, _vm$data$user, _vm$data2, _vm$data2$user, _vm$data3, _vm$data4, _vm$data$jops_pengaju, _vm$data$jops_pengaju2, _vm$data$jops_pengaju3, _vm$data$jops_pengaju4, _vm$data$jops_pengaju5, _vm$data5, _vm$data5$user, _vm$data6, _vm$data6$user, _vm$data7, _vm$data8;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "yogi-ptsal"
  }, [_c('b-card', {}, [_c('div', {
    staticClass: "custom-search d-flex justify-content-between"
  }, [_c('b-media', {
    attrs: {
      "no-body": "",
      "vertical-align": "center"
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-75"
  }, [_c('b-avatar', {
    attrs: {
      "size": "38"
    }
  })], 1), _c('b-media-body', [_c('h6', {
    staticClass: "mb-25"
  }, [_vm._v(_vm._s((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : (_vm$data$user = _vm$data.user) === null || _vm$data$user === void 0 ? void 0 : _vm$data$user.perusahaan))]), _c('small', [_c('i', [_vm._v(_vm._s((_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$user = _vm$data2.user) === null || _vm$data2$user === void 0 ? void 0 : _vm$data2$user.full_name))])]), _c('b-card-text', [_c('strong', [_vm._v("Diajukan")]), _vm._v(" " + _vm._s(_vm.dateHumanReadAble((_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : _vm$data3.created_at)) + " dengan "), _c('strong', [_vm._v("Rencana Uraian Kegiatan")]), _vm._v(" : "), _c('strong', [_vm._v(_vm._s((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : _vm$data4.rencana_uraian_keg))])])], 1)], 1), _c('span', [_c('h1', [_vm._v("#Form Operasional")])])], 1), _c('b-row', {
    staticClass: "mt-2"
  }, [_c('b-col', {
    attrs: {
      "md": "8",
      "cols": "6"
    }
  }), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal",
      "label-for": "lbl-tanggal"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', [_vm._v("Tanggal")]), _c('a', {
          staticClass: "ml-1 text-primary"
        }, [_vm._v(" ( "), _c('feather-icon', {
          attrs: {
            "icon": "CalendarIcon"
          }
        }), _vm._v(" Jika kosong , otomatis set as Today ) ")], 1)])];
      },
      proxy: true
    }])
  }, [_c('validation-provider', {
    attrs: {
      "name": "Tanggal"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-input-group', [_c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "CalendarIcon"
          }
        })], 1), _vm.isNotJasaOPSViewer() ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "id": "lbl-tanggal",
            "static": "true",
            "state": errors.length > 0 ? false : null,
            "config": _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.jops_pengajuan_dts.tanggal,
            callback: function callback($$v) {
              _vm.$set(_vm.form.jops_pengajuan_dts, "tanggal", $$v);
            },
            expression: "form.jops_pengajuan_dts.tanggal"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "lbl-tanggal",
            "value": _vm.dateHumanReadAble(_vm.form.jops_pengajuan_dts.tanggal),
            "readonly": true,
            "state": errors.length > 0 ? false : null
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "divider"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    staticClass: "d-block",
    attrs: {
      "pill": "",
      "variant": 'light-primary'
    }
  }, [_vm._v(" Peralatan ")])], 1)]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Crane",
      "label-for": "lbl-plt-crane"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Crane"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-input-group', [_vm.isNotJasaOPSViewer() ? _c('v-select', {
          staticClass: "flex-grow-1",
          attrs: {
            "id": "lbl-plt-crane",
            "multiple": "",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.options.plt_crane,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "label": "text",
            "item-text": "text",
            "item-value": "value"
          },
          model: {
            value: _vm.form.jops_pengajuan_dts.plt_crane,
            callback: function callback($$v) {
              _vm.$set(_vm.form.jops_pengajuan_dts, "plt_crane", $$v);
            },
            expression: "form.jops_pengajuan_dts.plt_crane"
          }
        }) : _c('span', _vm._l(_vm.form.jops_pengajuan_dts.plt_crane, function (item, index) {
          return _c('span', {
            key: index,
            attrs: {
              "id": index
            }
          }, [_vm._v(" " + _vm._s(item) + " - ")]);
        }), 0)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Forklift",
      "label-for": "lbl-plt-forklift"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Forklift"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-input-group', [_vm.isNotJasaOPSViewer() ? _c('v-select', {
          staticClass: "flex-grow-1",
          attrs: {
            "id": "lbl-plt-forklift",
            "multiple": "",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.options.plt_forklift,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "label": "text",
            "item-text": "text",
            "item-value": "value"
          },
          model: {
            value: _vm.form.jops_pengajuan_dts.plt_forklift,
            callback: function callback($$v) {
              _vm.$set(_vm.form.jops_pengajuan_dts, "plt_forklift", $$v);
            },
            expression: "form.jops_pengajuan_dts.plt_forklift"
          }
        }) : _c('span', _vm._l(_vm.form.jops_pengajuan_dts.plt_forklift, function (item, index) {
          return _c('span', {
            key: index,
            attrs: {
              "id": index
            }
          }, [_vm._v(" " + _vm._s(item) + " - ")]);
        }), 0)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tronton",
      "label-for": "lbl-plt-tronton"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Tronton"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-input-group', [_vm.isNotJasaOPSViewer() ? _c('v-select', {
          staticClass: "flex-grow-1",
          attrs: {
            "id": "lbl-plt-tronton",
            "multiple": "",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.options.plt_tronton,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "label": "text",
            "item-text": "text",
            "item-value": "value"
          },
          model: {
            value: _vm.form.jops_pengajuan_dts.plt_tronton,
            callback: function callback($$v) {
              _vm.$set(_vm.form.jops_pengajuan_dts, "plt_tronton", $$v);
            },
            expression: "form.jops_pengajuan_dts.plt_tronton"
          }
        }) : _c('span', _vm._l(_vm.form.jops_pengajuan_dts.plt_tronton, function (item, index) {
          return _c('span', {
            key: index,
            attrs: {
              "id": index
            }
          }, [_vm._v(" " + _vm._s(item) + " - ")]);
        }), 0)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "divider"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    staticClass: "d-block",
    attrs: {
      "pill": "",
      "variant": 'light-primary'
    }
  }, [_vm._v(" Tenaga Kerja ")])], 1)]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rigger",
      "label-for": "lbl-Rigger"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Rigger",
      "vid": "Rigger",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-input-group', [_c('b-form-input', {
          ref: "riggerInput",
          attrs: {
            "id": "lbl-Rigger",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "placeholder": 'Rigger',
            "disabled": !_vm.isNotJasaOPSViewer()
          },
          model: {
            value: _vm.form.jops_pengajuan_dts.tk_rigger,
            callback: function callback($$v) {
              _vm.$set(_vm.form.jops_pengajuan_dts, "tk_rigger", $$v);
            },
            expression: "form.jops_pengajuan_dts.tk_rigger"
          }
        }), _c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.$refs.riggerInput.focus();
            }
          }
        }, [_vm._v(" PRS ")])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tallyman",
      "label-for": "lbl-Tallyman"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Tallyman",
      "vid": "Tallyman",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-input-group', [_c('b-form-input', {
          attrs: {
            "id": "lbl-Tallyman",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "placeholder": 'Tallyman',
            "disabled": !_vm.isNotJasaOPSViewer()
          },
          model: {
            value: _vm.form.jops_pengajuan_dts.tk_tallyman,
            callback: function callback($$v) {
              _vm.$set(_vm.form.jops_pengajuan_dts, "tk_tallyman", $$v);
            },
            expression: "form.jops_pengajuan_dts.tk_tallyman"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_vm._v(" PRS ")])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "HSE",
      "label-for": "lbl-HSE"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "HSE",
      "vid": "HSE",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-input-group', [_c('b-form-input', {
          attrs: {
            "id": "lbl-HSE",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "placeholder": 'HSE',
            "disabled": !_vm.isNotJasaOPSViewer()
          },
          model: {
            value: _vm.form.jops_pengajuan_dts.tk_hse,
            callback: function callback($$v) {
              _vm.$set(_vm.form.jops_pengajuan_dts, "tk_hse", $$v);
            },
            expression: "form.jops_pengajuan_dts.tk_hse"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_vm._v(" PRS ")])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Supervisor",
      "label-for": "lbl-Supervisor"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Supervisor",
      "vid": "Supervisor",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-input-group', [_c('b-form-input', {
          attrs: {
            "id": "lbl-Supervisor",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "placeholder": 'Supervisor',
            "disabled": !_vm.isNotJasaOPSViewer()
          },
          model: {
            value: _vm.form.jops_pengajuan_dts.tk_supervisor,
            callback: function callback($$v) {
              _vm.$set(_vm.form.jops_pengajuan_dts, "tk_supervisor", $$v);
            },
            expression: "form.jops_pengajuan_dts.tk_supervisor"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_vm._v(" PRS ")])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jettyman",
      "label-for": "lbl-Jettyman"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Jettyman",
      "vid": "Jettyman",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-input-group', [_c('b-form-input', {
          attrs: {
            "id": "lbl-Jettyman",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "placeholder": 'Jettyman',
            "disabled": !_vm.isNotJasaOPSViewer()
          },
          model: {
            value: _vm.form.jops_pengajuan_dts.tk_jettyman,
            callback: function callback($$v) {
              _vm.$set(_vm.form.jops_pengajuan_dts, "tk_jettyman", $$v);
            },
            expression: "form.jops_pengajuan_dts.tk_jettyman"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_vm._v(" PRS ")])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Security",
      "label-for": "lbl-Security"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Security",
      "vid": "Security",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-input-group', [_c('b-form-input', {
          attrs: {
            "id": "lbl-Security",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "placeholder": 'Security',
            "disabled": !_vm.isNotJasaOPSViewer()
          },
          model: {
            value: _vm.form.jops_pengajuan_dts.tk_security,
            callback: function callback($$v) {
              _vm.$set(_vm.form.jops_pengajuan_dts, "tk_security", $$v);
            },
            expression: "form.jops_pengajuan_dts.tk_security"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "is-text": ""
          }
        }, [_vm._v(" PRS ")])], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "divider"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    staticClass: "d-block",
    attrs: {
      "pill": "",
      "variant": 'light-primary'
    }
  }, [_vm._v(" Others ")])], 1)]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "8",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Work Description",
      "label-for": "lbl-Work Description"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Work Description",
      "vid": "Work Description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('b-input-group', [_c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "BriefcaseIcon"
          }
        })], 1), _c('b-form-input', {
          attrs: {
            "id": "lbl-Work Description",
            "state": errors.length > 0 ? false : null,
            "placeholder": 'Muat IBC',
            "disabled": !_vm.isNotJasaOPSViewer()
          },
          model: {
            value: _vm.form.jops_pengajuan_jrs.work_desc,
            callback: function callback($$v) {
              _vm.$set(_vm.form.jops_pengajuan_jrs, "work_desc", $$v);
            },
            expression: "form.jops_pengajuan_jrs.work_desc"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Order No",
      "label-for": "lbl-Order No"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Order No",
      "vid": "Order No"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('b-input-group', [_c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "HashIcon"
          }
        })], 1), _c('b-form-input', {
          attrs: {
            "id": "lbl-Order No",
            "state": errors.length > 0 ? false : null,
            "placeholder": '',
            "disabled": !_vm.isNotJasaOPSViewer()
          },
          model: {
            value: _vm.form.jops_pengajuan_jrs.order_no,
            callback: function callback($$v) {
              _vm.$set(_vm.form.jops_pengajuan_jrs, "order_no", $$v);
            },
            expression: "form.jops_pengajuan_jrs.order_no"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Segment",
      "label-for": "lbl-Segment"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', [_vm._v("Segment")]), _c('a', {
          staticClass: "ml-1 text-primary",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.navigateToRegisterSegment.apply(null, arguments);
            }
          }
        }, [_vm._v(" ( "), _c('feather-icon', {
          attrs: {
            "icon": "LinkIcon"
          }
        }), _vm._v(" Register New) ")], 1)])];
      },
      proxy: true
    }])
  }, [_c('validation-provider', {
    attrs: {
      "name": "Segment",
      "vid": "Segment"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('b-input-group', [_c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "LinkIcon"
          }
        })], 1), _c('v-select', {
          staticClass: "flex-grow-1",
          attrs: {
            "disabled": !_vm.isNotJasaOPSViewer(),
            "id": "lbl-Segment",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.options.segments,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "label": "text",
            "item-text": "text",
            "item-value": "value"
          },
          model: {
            value: _vm.form.jops_pengajuan_jrs.segment,
            callback: function callback($$v) {
              _vm.$set(_vm.form.jops_pengajuan_jrs, "segment", $$v);
            },
            expression: "form.jops_pengajuan_jrs.segment"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Vessel Identity",
      "label-for": "lbl-Vessel Identity"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Vessel Identity",
      "vid": "Vessel Identity"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-input-group', [_c('b-input-group-prepend', {
          attrs: {
            "is-text": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "AnchorIcon"
          }
        })], 1), _c('b-form-input', {
          attrs: {
            "id": "lbl-Vessel Identity",
            "state": errors.length > 0 ? false : null,
            "placeholder": ''
          },
          model: {
            value: _vm.form.jops_pengajuan_jrs.vessel_identity,
            callback: function callback($$v) {
              _vm.$set(_vm.form.jops_pengajuan_jrs, "vessel_identity", $$v);
            },
            expression: "form.jops_pengajuan_jrs.vessel_identity"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), (_vm$data$jops_pengaju = _vm.data.jops_pengajuan_dts[0]) !== null && _vm$data$jops_pengaju !== void 0 && (_vm$data$jops_pengaju2 = _vm$data$jops_pengaju.user) !== null && _vm$data$jops_pengaju2 !== void 0 && _vm$data$jops_pengaju2.full_name ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "divider"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_vm._v(" Updated Form By : "), _c('b-badge', {
    staticClass: "d-block",
    attrs: {
      "pill": "",
      "variant": 'light-primary'
    }
  }, [_vm._v(" " + _vm._s((_vm$data$jops_pengaju3 = _vm.data.jops_pengajuan_dts[0]) === null || _vm$data$jops_pengaju3 === void 0 ? void 0 : (_vm$data$jops_pengaju4 = _vm$data$jops_pengaju3.user) === null || _vm$data$jops_pengaju4 === void 0 ? void 0 : _vm$data$jops_pengaju4.full_name) + " ")]), _vm._v(" Last Updated At : " + _vm._s(_vm.dateHumanReadAble((_vm$data$jops_pengaju5 = _vm.data.jops_pengajuan_dts[0]) === null || _vm$data$jops_pengaju5 === void 0 ? void 0 : _vm$data$jops_pengaju5.created_at)) + " ")], 1)])]) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.isNotJasaOPSViewer() ? _c('b-button', {
    staticClass: "mt-1",
    attrs: {
      "block": "",
      "variant": "gradient-primary",
      "disabled": _vm.fetchLoading
    },
    on: {
      "click": function click($event) {
        return _vm.handleSimpanFormOperasional();
      }
    }
  }, [!_vm.fetchLoading ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "DatabaseIcon"
    }
  }) : _vm._e(), _vm.fetchLoading ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Simpan Form Operasional')
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _c('b-card', [_c('div', {
    staticClass: "custom-search d-flex justify-content-between"
  }, [_c('b-media', {
    attrs: {
      "no-body": "",
      "vertical-align": "center"
    }
  }, [_c('b-media-aside', {
    staticClass: "mr-75"
  }, [_c('b-avatar', {
    attrs: {
      "size": "38"
    }
  })], 1), _c('b-media-body', [_c('h6', {
    staticClass: "mb-25"
  }, [_vm._v(_vm._s((_vm$data5 = _vm.data) === null || _vm$data5 === void 0 ? void 0 : (_vm$data5$user = _vm$data5.user) === null || _vm$data5$user === void 0 ? void 0 : _vm$data5$user.perusahaan))]), _c('small', [_c('i', [_vm._v(_vm._s((_vm$data6 = _vm.data) === null || _vm$data6 === void 0 ? void 0 : (_vm$data6$user = _vm$data6.user) === null || _vm$data6$user === void 0 ? void 0 : _vm$data6$user.full_name))])]), _c('b-card-text', [_c('strong', [_vm._v("Diajukan")]), _vm._v(" " + _vm._s(_vm.dateHumanReadAble((_vm$data7 = _vm.data) === null || _vm$data7 === void 0 ? void 0 : _vm$data7.created_at)) + " dengan "), _c('strong', [_vm._v("Rencana Uraian Kegiatan")]), _vm._v(" : "), _c('strong', [_vm._v(_vm._s((_vm$data8 = _vm.data) === null || _vm$data8 === void 0 ? void 0 : _vm$data8.rencana_uraian_keg))])])], 1)], 1), _c('span', [_c('h1', [_vm._v("#Daftar Kegiatan Operasional")])])], 1), _c('div', {
    staticClass: "d-flex justify-content-end align-items-end"
  }, [_vm.isNotJasaOPSViewer() ? _c('b-button', {
    staticClass: "mt-1",
    attrs: {
      "variant": "gradient-success",
      "disabled": _vm.fetchLoading,
      "to": {
        name: 'pelayanan-jasa-operasional-pengajuan-wizard-kegiatan',
        params: {
          jops_pengajuan_dt_id: _vm.data.id,
          jops_pengajuan_dt: _vm.data.jops_pengajuan_dts[0],
          api: 'jops_pengajuan',
          previous_link: {
            id: _vm.data.id,
            name: 'pelayanan-jasa-operasional-pengajuan-wizard',
            api: 'jops_pengajuan'
          }
        }
      }
    }
  }, [!_vm.fetchLoading ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "BookIcon"
    }
  }) : _vm._e(), _vm.fetchLoading ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Buat Daftar Kegiatan Operasional')
    }
  })], 1) : _vm._e()], 1), _c('b-card', [_vm.data.jops_pengajuan_dts[0].jops_pengajuan_dts_kegs.length !== 0 ? _c('b-list-group', _vm._l(_vm.data.jops_pengajuan_dts[0].jops_pengajuan_dts_kegs, function (item, id) {
    var _item$note, _item$user;
    return _c('b-list-group-item', {
      key: id,
      staticClass: "p-1"
    }, [_c('div', {
      staticClass: "d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between"
    }, [_c('span', {
      staticClass: "flex-grow-1 w-100"
    }, [_c('b-row', [_c('b-col', {
      attrs: {
        "md": "3",
        "cols": "12"
      }
    }, [_c('ul', {
      staticClass: "list-unstyled"
    }, [_c('li', [_c('strong', [_vm._v(_vm._s(item.uraian_kegiatan))])]), _c('li', [_c('b-badge', {
      staticClass: "badge-round",
      attrs: {
        "variant": "info",
        "pill": ""
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "LayersIcon"
      }
    }), _c('strong', [_vm._v("(" + _vm._s(item.jumlah1) + " " + _vm._s(item.jumlah_s1 || 'TON') + " )")])], 1), _vm._v(" / "), _c('b-badge', {
      staticClass: "badge-round",
      attrs: {
        "variant": "info",
        "pill": ""
      }
    }, [_c('strong', [_vm._v("(" + _vm._s(item.jumlah2) + " " + _vm._s(item.jumlah_s2 || 'M3') + " )")])])], 1)])]), _c('b-col', {
      attrs: {
        "md": "3",
        "sm": "12",
        "cols": "12"
      }
    }, [_c('ul', {
      staticClass: "list-unstyled"
    }, [_c('li', {
      staticClass: "mb-1"
    }, [_c('b-badge', {
      staticClass: "badge-round w-100",
      attrs: {
        "variant": "light-primary",
        "pill": ""
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "ClockIcon"
      }
    }), _c('i', [_vm._v("Jam")]), _vm._v(" "), _c('strong', [_vm._v(_vm._s(item.time) + " / " + _vm._s(item.total_jam))])], 1)], 1), _c('li', [_c('b-badge', {
      staticClass: "badge-round w-100",
      attrs: {
        "variant": "light-primary",
        "pill": ""
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "ClockIcon"
      }
    }), _c('strong', [_vm._v(_vm._s(_vm.dateHumanReadAble(item.mulai, 'D MMMM YYYY')))]), _c('i', [_vm._v(" Sampai ")]), _c('strong', [_vm._v(_vm._s(_vm.dateHumanReadAble(item.selesai, 'D MMMM YYYY')))])], 1)], 1)])]), _c('b-col', {
      attrs: {
        "md": "6",
        "cols": "12"
      }
    }, [_c('ul', {
      staticClass: "list-unstyled"
    }, [_c('li', {
      staticClass: "mb-1"
    }, [_c('b-badge', {
      staticClass: "badge-round w-100",
      attrs: {
        "variant": "light-secondary",
        "pill": ""
      }
    }, [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "FeatherIcon"
      }
    }), _vm._v(" Notes : " + _vm._s((_item$note = item === null || item === void 0 ? void 0 : item.note) !== null && _item$note !== void 0 ? _item$note : '-') + " ")], 1)], 1), _c('li', [item.jasa_dermaga ? _c('b-badge', {
      staticClass: "badge-round",
      attrs: {
        "variant": "primary",
        "pill": ""
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "AnchorIcon"
      }
    }), _c('small', [_vm._v(" Jasa Dermaga")])], 1) : _vm._e(), _vm._v("   "), item.jasa_stevodoring ? _c('b-badge', {
      staticClass: "badge-round",
      attrs: {
        "variant": "primary",
        "pill": ""
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "TruckIcon"
      }
    }), _c('small', [_vm._v(" Jasa Stevodoring")])], 1) : _vm._e(), _vm._v("   "), item.jasa_cargodoring ? _c('b-badge', {
      staticClass: "badge-round",
      attrs: {
        "variant": "primary",
        "pill": ""
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "BoxIcon"
      }
    }), _c('small', [_vm._v(" Jasa Cargodoring")])], 1) : _vm._e(), _vm._v("   "), item.lift_on_off ? _c('b-badge', {
      staticClass: "badge-round",
      attrs: {
        "variant": "primary",
        "pill": ""
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "Maximize2Icon"
      }
    }), _c('small', [_vm._v(" Lift ON / OFF")])], 1) : _vm._e()], 1)])])], 1)], 1), _vm.isNotJasaOPSViewer() ? _c('div', {
      staticClass: "d-flex flex-column flex-md-row align-items-center mt-2 mt-md-0"
    }, [_c('b-button', {
      staticClass: "rounded-circle p-1 d-flex align-items-center justify-content-center",
      attrs: {
        "variant": "outline-primary",
        "to": {
          name: 'pelayanan-jasa-operasional-pengajuan-wizard-kegiatan',
          params: {
            id: item.id,
            jops_pengajuan_dt_id: _vm.data.id,
            jops_pengajuan_dt: _vm.data.jops_pengajuan_dts[0],
            api: 'jops_pengajuan_dts_keg',
            previous_link: {
              id: _vm.data.id,
              name: 'pelayanan-jasa-operasional-pengajuan-wizard',
              api: 'jops_pengajuan'
            }
          }
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "EditIcon"
      }
    })], 1), _c('span', {
      staticClass: "rounded-circle border border-danger d-flex align-items-center justify-content-center m-1",
      staticStyle: {
        "width": "32px",
        "height": "32px",
        "cursor": "pointer"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.handleDeleteKegiatanOperasional(item.id);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "text-danger",
      attrs: {
        "icon": "TrashIcon",
        "size": "16"
      }
    })], 1)], 1) : _vm._e()]), _c('hr'), _c('small', [_c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "ClockIcon"
      }
    }), _vm._v(" Created at " + _vm._s(_vm.dateHumanReadAble(item === null || item === void 0 ? void 0 : item.created_at)) + " and "), _c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "ClockIcon"
      }
    }), _vm._v(" Last Updated at " + _vm._s(_vm.dateHumanReadAble(item === null || item === void 0 ? void 0 : item.updated_at)) + " "), _c('feather-icon', {
      staticClass: "mr-25",
      attrs: {
        "icon": "UserIcon"
      }
    }), _vm._v(" By " + _vm._s(item === null || item === void 0 ? void 0 : (_item$user = item.user) === null || _item$user === void 0 ? void 0 : _item$user.full_name))], 1)]);
  }), 1) : _c('span', [_vm._v(" -")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }